import { Component } from '@angular/core';
import { ContentBlockViewComponent } from './content-block-view-component';

@Component({
    selector: 'tyt-missing-content-block-view',
    template: `
        Block type <code>{{block.type}}</code>: No view available
    `
})
export class MissingContentBlockViewComponent extends ContentBlockViewComponent {
}