import { Component, ComponentRef, HostBinding, Input, Output, Type, ViewChild, ViewContainerRef, inject } from '@angular/core';
import { ContentBlocksService } from './content-blocks.service';
import { ContentBlockViewComponent } from './content-block-view-component';
import { MissingContentBlockViewComponent } from './missing-content-block-view.component';
import { ContentBlock } from './content-block';
import { branch } from '@tytapp/common';
import { firstValueFrom, Subject } from 'rxjs';

@Component({
    selector: 'tyt-content-block',
    template: `
        <ng-template #template></ng-template>
    `,
    styles: [``]
})
export class ContentBlockHostComponent {
    private blockTypes = inject(ContentBlocksService);
    private fireReady: Function;
    private ready = new Promise(r => this.fireReady = r);

    ngAfterViewInit() {
        this.fireReady();
    }

    @ViewChild('template', { read: ViewContainerRef }) private viewContainerRef: ViewContainerRef;
    @Input() get block() { return this._block; }
    @HostBinding('attr.data-type') get type() { return this._block.type ?? 'unknown'; }
    @Output() viewLoaded = new Subject<void>();

    private _block: ContentBlock;
    private _blockType: string;
    private _componentRef: ComponentRef<ContentBlockViewComponent>;

    get component() { return this._componentRef?.instance; }

    private updateTimeout;

    set block(value) {
        this._block = value;

        if (!value) {
            this.viewContainerRef?.clear();
            this._blockType = undefined;
            return;
        }

        if (this._blockType === value.type)
            return;

        this._blockType = value.type;
        branch(() => this.createView(value));
    }

    private async createView(block: ContentBlock) {
        let type = this.blockTypes.getFromBlock(block);
        let component: Type<any> = MissingContentBlockViewComponent;

        if (type?.deferredViewComponent) {
            component = await type.deferredViewComponent();
        } else if (type?.viewComponent) {
            component = type.viewComponent;
        }

        await this.ready;
        this.viewContainerRef.clear();
        this._componentRef = this.viewContainerRef.createComponent<ContentBlockViewComponent>(component);
        if (this.component)
            this.component.block = block;

        await firstValueFrom(this._componentRef.instance.viewLoaded);
        setTimeout(() => this.viewLoaded.next());
    }


}