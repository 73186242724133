
<div class="multi-column">
  <div class="primary-column">
    @for (blockSlot of primaryBlocks; track blockSlot.index) {
      @if (!blockSlot.block.hidden) {
        <tyt-content-block
          [block]="blockSlot.block"
          [class.floats-left]="blockSlot.block.float === 'left'"
          [class.floats-right]="blockSlot.block.float === 'right'"
          [style.order]="blockSlot.index"
          (viewLoaded)="viewDidLoad(blockSlot)"
          />
      }
    }
  </div>
  <div class="secondary-column" [class.empty]="secondaryBlocks.length === 0">
    @for (blockSlot of secondaryBlocks; track blockSlot.index) {
      @if (!blockSlot.block.hidden) {
        <tyt-content-block
          [block]="blockSlot.block"
          [class.floats-left]="blockSlot.block.float === 'left'"
          [class.floats-right]="blockSlot.block.float === 'right'"
          [style.order]="blockSlot.index"
          (viewLoaded)="viewDidLoad(blockSlot)"
          />
      }
    }
  </div>
</div>