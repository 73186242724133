import { ContentBlockEditorComponent } from './content-block-editor-component';
import { Component } from '@angular/core';

@Component({
    selector: 'tyt-missing-content-block-editor',
    template: `
        Block type <code>{{block.type}}</code>: No editor available
    `
})
export class MissingContentBlockEditorComponent extends ContentBlockEditorComponent {
}