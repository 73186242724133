import { Component, HostBinding, Input, Output, inject } from '@angular/core';
import { ContentBlock } from '../content-block';
import { Subject } from 'rxjs';
import { ContentBlockType } from '../content-block-type';
import { Base64, Shell } from '@tytapp/common';
import { ContentBlockPickerComponent } from '../content-block-picker/content-block-picker.component';
import { ContentEditorComponent } from '../content-editor/content-editor.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { v4 as uuid } from 'uuid';

@Component({
    selector: 'tyt-content-block-editor-ui',
    templateUrl: './content-block-editor-ui.component.html',
    styleUrls: ['./content-block-editor-ui.component.scss'],
})
export class ContentBlockEditorUiComponent {
    private shell = inject(Shell);
    private editor = inject(ContentEditorComponent);
    private snackBar = inject(MatSnackBar);
    private base64 = inject(Base64);

    @Input() block: ContentBlock;
    @Output() removed = new Subject<void>();

    get blockType() { return this.editor.blockType(this.block); }
    get locked() { return this.editor.locked; }
    get editorMode() { return this.editor.editorMode; }
    get quickBlockTypes() { return this.editor.quickBlockTypes; }

    @HostBinding('class.reordering')
    get isReordering() { return this.editorMode === 'reorder'; }

    @HostBinding('class.editing')
    get isEditing() { return this.mode !== 'preview'; }

    @HostBinding('class.ctrl-down')
    get ctrlDown() { return this.editor.ctrlDown; }

    @HostBinding('class.shift-down')
    get shiftDown() { return this.editor.shiftDown; }

    @HostBinding('class.alt-down')
    get altDown() { return this.editor.altDown; }

    private _mode: 'edit' | 'json' | 'preview' = 'preview';

    set mode(value) {
        this._mode = value;
    }

    get mode() {
        if (this.blockType?.isLayout === true && this.blockType?.editable === false)
            return 'json';

        if (this.blockType?.isLayout === true && this._mode === 'preview')
            return 'edit';

        // if (this.blockType?.editable === false && this._mode === 'edit')
        //     return 'preview';

        return this._mode;
    }

    blockJson: string;

    ngOnInit() {
        if (this.block.__initialEditorMode) {
            this.mode = this.block.__initialEditorMode;
            setTimeout(() => {
                delete this.block.__initialEditorMode;
            }, 500);
        }
    }
    delayAutoSave() {
        this.editor.delayAutoSave();
    }

    duplicate() {
        let dupe = <ContentBlock>JSON.parse(JSON.stringify({ ...this.block, id: uuid() }));
        let index = this.editor.blocks.indexOf(this.block);
        this.editor.blocks.splice(index, 0, dupe);
        this.setMode('preview');
        this.snackBar.open('Block duplicated', undefined, { duration: 1500 });
    }

    remove() {
        this.editor.removeBlock(this.block);
    }

    addBlockBefore(type: ContentBlockType) {
        this.editor.addBlockBefore(this.block, type);
    }

    showPicker() {
        this.shell.showDialog(ContentBlockPickerComponent, type => this.addBlockBefore(type));
    }

    setMode(mode: 'edit' | 'preview' | 'json') {
        let previousMode = this.mode;
        this.mode = mode;
        if (previousMode !== 'json' && mode === 'json')
            this.blockJson = JSON.stringify(this.block, undefined, 2);
    }

    saveJson() {
        try {
            Object.assign(this.block, JSON.parse(this.blockJson));
        } catch (e) {
            alert(`Parse error in JSON: ${e.message} -- Please correct errors and try again.`);
            return;
        }

        this.setMode('edit');
    }


    openInBlockWorkshop() {
        window.open(`/engineering/block-workshop?block=${this.base64.encode(JSON.stringify(this.block))}`)
    }
}