import { DragDropModule } from '@angular/cdk/drag-drop';
import { TextFieldModule } from '@angular/cdk/text-field';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ApiPage, PagesApi } from '@tytapp/api';
import { TYTCommonModule } from '@tytapp/common';
import { CommonUiModule } from '@tytapp/common-ui';
import { SlugService } from '@tytapp/slugs';
import { ContentBlockEditorUiComponent } from './content-block-editor-ui/content-block-editor-ui.component';
import { ContentBlockEditorHostComponent } from './content-block-editor.component';
import { ContentBlockPickerComponent } from './content-block-picker/content-block-picker.component';
import { ContentBlockHostComponent } from './content-block.component';
import { ContentBlocksService } from './content-blocks.service';
import { ContentCardComponent } from './content-card/content-card.component';
import { ContentEditorComponent } from './content-editor/content-editor.component';
import { ContentViewComponent } from './content-view/content-view.component';
import { HighlightAsteriskPipe } from './highlight-asterisk.pipe';
import { MissingContentBlockEditorComponent } from './missing-content-block-editor.component';
import { MissingContentBlockViewComponent } from './missing-content-block-view.component';
import { RouterModule } from '@angular/router';
import { MatSnackBarModule } from '@angular/material/snack-bar';


const COMPONENTS = [
    ContentEditorComponent,
    ContentViewComponent,
    HighlightAsteriskPipe,
    ContentBlockHostComponent,
    ContentBlockEditorHostComponent,
    MissingContentBlockEditorComponent,
    MissingContentBlockViewComponent,
    ContentBlockPickerComponent,
    ContentCardComponent,
    ContentBlockEditorUiComponent
];

const IMPORTS = [
    CommonModule,
    FormsModule,
    TYTCommonModule,
    RouterModule,
    CommonUiModule,
    DragDropModule,
    MatButtonModule,
    MatFormFieldModule,
    MatSnackBarModule,
    MatIconModule,
    MatInputModule,
    MatTooltipModule,
    TYTCommonModule,
    TextFieldModule,
    MatMenuModule,
    MatDividerModule,
    MatTooltipModule,
    MatButtonToggleModule,
    CommonUiModule
];

@NgModule({
    declarations: COMPONENTS,
    exports: [
        ...COMPONENTS,
        ...IMPORTS
    ],
    imports: [
        ...IMPORTS
    ]
})
export class ContentModule {
    private pagesApi = inject(PagesApi);

    constructor() {
        SlugService.registerType<ApiPage>({
            type: 'CMS::Page',
            friendlyName: 'Page',
            getContent: slug => this.pagesApi.getPage(slug.uuid).toPromise(),
            getUrl: slug => {
                return `/pages/${slug.uuid}`;
            },
            getMetadataForContent: async content => ({
                title: content.title,
                url: content.url
            }),
        });
    }

    static forRoot(): ModuleWithProviders<ContentModule> {
        return {
            providers: [
                ContentBlocksService
            ],
            ngModule: ContentModule
        };
    }
}
