<mat-menu #addMenu>
  @for (blockType of quickBlockTypes; track blockType) {
    <a mat-menu-item (click)="addBlockBefore(blockType)">
      <mat-icon>{{blockType.icon}}</mat-icon>
      {{blockType.label}}
    </a>
  }
  <a mat-menu-item (click)="showPicker()">
    <mat-icon>more_horiz</mat-icon>
    More
  </a>
</mat-menu>

<mat-menu #blockMenu>
  @if (!block.float) {
    <button mat-menu-item (click)="duplicate()" [disabled]="locked">
      <mat-icon>code</mat-icon>
      Duplicate
    </button>
    <mat-divider></mat-divider>
    <button mat-menu-item (click)="setMode('json')">
      <mat-icon>code</mat-icon>
      JSON
    </button>
    <button mat-menu-item (click)="openInBlockWorkshop()">
      <mat-icon>open_in_new</mat-icon>
      Open in Block Workshop
    </button>
    <mat-divider></mat-divider>
    @if (!block.hidden) {
      <button mat-menu-item (click)="block.hidden = true" [disabled]="locked">
        <mat-icon>hide_image</mat-icon>
        Make Hidden
      </button>
    }
    @if (block.hidden) {
      <button mat-menu-item (click)="block.hidden = false" [disabled]="locked">
        <mat-icon>image</mat-icon>
        Make Visible
      </button>
    }
    <button mat-menu-item (click)="block.float = 'left'" [disabled]="locked">
      <mat-icon>turn_left</mat-icon>
      Float Left
    </button>
    <button mat-menu-item (click)="block.float = 'right'" [disabled]="locked">
      <mat-icon>turn_right</mat-icon>
      Float Right
    </button>
  }
  @if (block.float) {
    <button mat-menu-item (click)="block.float = undefined" [disabled]="locked">
      <mat-icon>adjust</mat-icon>
      Remove Float
    </button>
  }
</mat-menu>

<fieldset class="block" [attr.data-type]="blockType?.id"
  [class.float-left]="block.float === 'left'" [class.float-right]="block.float === 'right'">
  <legend>
    <label>
      @if (editorMode === 'reorder') {
        <mat-icon>drag_handle</mat-icon>
      }
      @if (block.hidden) {
        [Hidden]
      }
      {{blockType?.label}}
    </label>
    <div class="spacer"></div>

    <div class="actions">

      @if (isEditing || isReordering) {
        <!-- NOTE: Duplicated below for non-editable blocks -->
        <button mat-button (click)="block.secondary = !block.secondary" [disabled]="locked" matTooltip="Click to change column">
          <mat-icon>{{ block.secondary ? 'view_sidebar' : 'table_rows'}}</mat-icon>
          {{ block.secondary ? 'Sidebar' : 'Main' }}
        </button>
      }
      @if (isEditing && !blockType.isLayout) {
        <button mat-icon-button (click)="setMode('preview')" matTooltip="Return to Preview">
          <mat-icon>done</mat-icon>
        </button>
      }

      @if (!isReordering) {
        @if (mode === 'edit') {
          <button mat-icon-button [matMenuTriggerFor]="blockMenu">
            <mat-icon>more_vert</mat-icon>
          </button>
        }
        @if (mode === 'preview') {
          <button mat-icon-button (click)="setMode('edit')" matTooltip="Edit block">
            <mat-icon>edit</mat-icon>
          </button>
        }
      }

      @if (isEditing || ctrlDown) {
        <button href="javascript:;" mat-icon-button (click)="remove()" [matTooltip]="shiftDown ? 'Delete without confirmation' : 'Delete'"
          [disabled]="locked">
          <mat-icon>delete</mat-icon>
        </button>
      }

      @if (!isEditing || blockType.isLayout) {
        <button mat-icon-button [matMenuTriggerFor]="addMenu"
          class="add" matTooltip="Add block before" [disabled]="locked">
          <mat-icon>add</mat-icon>
        </button>
      }
    </div>
  </legend>
  <div class="content-block-move-placeholder" *cdkDragPlaceholder></div>

  @if (mode === 'edit') {
    @if (blockType.editable !== false) {
      <tyt-content-block-editor
        [block]="block"
        [locked]="locked"
        (delayedAutoSave)="delayAutoSave()"
      ></tyt-content-block-editor>
    } @else {
      <div class="alert alert-info muted">
        Note: The contents of this block are not editable, but you can still use the controls above to
        configure it.
      </div>
      <tyt-content-block [block]="block"></tyt-content-block>
    }
    <div class="action-buttons">
      <button mat-raised-button color="primary" (click)="setMode('preview')">
        <mat-icon>done</mat-icon>
        Done
      </button>
    </div>
  }

  @if (mode === 'preview') {
    <tyt-content-block [block]="block"></tyt-content-block>
  }

  @if (mode === 'json') {
    <div class="json-editor">
      <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>JSON</mat-label>
        <textarea matInput [(ngModel)]="blockJson"></textarea>
      </mat-form-field>
      <div class="action-buttons">
        <button mat-button (click)="setMode('edit')">Cancel</button>
        <button mat-raised-button color="primary" (click)="saveJson()" [disabled]="locked">
          <mat-icon>done</mat-icon>
          Save changes
        </button>
      </div>
    </div>
  }
</fieldset>
