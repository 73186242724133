import { Component, HostBinding, Input } from '@angular/core';
import { Icon } from '@tytapp/common';

@Component({
    selector: 'tyt-content-card',
    templateUrl: './content-card.component.html',
    styleUrls: ['./content-card.component.scss']
})
export class ContentCardComponent {
    @HostBinding('class.animate-in') animateIn = true;
    @Input() url: string;
    @Input() icon: Icon;
    @Input() title: string;
}