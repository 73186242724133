import { Component, ComponentRef, Input, Output, ViewContainerRef, inject } from '@angular/core';
import { ContentBlocksService } from './content-blocks.service';
import { ContentBlockEditorComponent } from './content-block-editor-component';
import { MissingContentBlockEditorComponent } from './missing-content-block-editor.component';
import { Subject, Subscription } from 'rxjs';
import { ContentBlock } from './content-block';

@Component({
    selector: 'tyt-content-block-editor',
    template: ``,
    styles: [``]
})
export class ContentBlockEditorHostComponent {
    private blockTypes = inject(ContentBlocksService);
    private viewContainerRef = inject(ViewContainerRef);

    private _block: ContentBlock;
    private _blockType: string;

    @Input() get block() { return this._block; }
    @Input() get locked() { return this.component?.locked ?? false; }
    set locked(value) {
        if (this.component) {
            setTimeout(() => this.component.locked = value);
        }
    }

    @Output() delayedAutoSave = new Subject<void>();

    private _componentRef: ComponentRef<ContentBlockEditorComponent>;
    get component() { return this._componentRef?.instance; }

    private componentSubs: Subscription;

    set block(value) {
        this._block = value;

        setTimeout(() => this.updateTypeFromBlock(value));
    }

    private async updateTypeFromBlock(block: ContentBlock) {
        if (!block) {
            this.viewContainerRef.clear();
            this.componentSubs?.unsubscribe();
        } else if (block.type !== this._blockType) {
            this.viewContainerRef.clear();
            this._blockType = block.type;
            this.componentSubs?.unsubscribe();
            this.componentSubs = new Subscription();

            let type = this.blockTypes.getFromBlock(block);
            let component = MissingContentBlockEditorComponent;

            if (type?.deferredEditorComponent) {
                component = await type.deferredEditorComponent();
            } else if (type?.editorComponent) {
                component = type.editorComponent;
            }

            this._componentRef = this.viewContainerRef.createComponent<ContentBlockEditorComponent>(component);
            this.componentSubs.add(this._componentRef.instance.delayedAutoSave.subscribe(() => this.delayedAutoSave.next()));
        }

        if (this.component)
            this.component.block = block;
    }
}