<div class="row-title">
  <a class="row-title-link" [url]="url">
    @if (icon) {
      <tyt-icon [size]="24" [icon]="icon" />
    }
    <span class="title-text cms-content">
      {{title}}
      <ng-content select=".title"></ng-content>
    </span>
  </a>
  <div class="spacer"></div>
  <div class="action-buttons">
    <ng-content select=".actions"></ng-content>
  </div>
</div>

<div class="component-container">
  <ng-content></ng-content>
</div>